import mixpanel from 'mixpanel-browser'
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {AuthService, StorageService} from 'app/services'
import {useQueryClient} from '@tanstack/react-query'
import {isProduction} from 'app/configs/config'
import {AuthModel} from 'types/auth'
import {useResetStore} from './layout'
import type {ITeamMemberDetails} from 'types/team'

interface AuthStore {
  authKey: string | undefined
  currentUser: ITeamMemberDetails | undefined

  setAuthKey: (authKey: string | undefined) => void
  setCurrentUser: (user: ITeamMemberDetails | undefined) => void
  init: () => void
  clearTokens: () => void
  requestUser: () => Promise<void>
  saveAuth: (auth: AuthModel | undefined) => void
}

const useAuthStore = create<AuthStore>()(
  devtools(
    (set, get) => ({
      authKey: StorageService.getAuthKey(),
      currentUser: undefined,

      setAuthKey: (authKey: string | undefined) => {
        set({authKey})
      },
      setCurrentUser: (currentUser: ITeamMemberDetails | undefined) => {
        set({currentUser})
      },
      init: () => {
        const authKey = StorageService.getAuthKey()
        set({authKey})
      },
      clearTokens: () => {
        set({
          authKey: undefined,
          currentUser: undefined,
        })
        StorageService.removeAuthTokens()
      },
      requestUser: async () => {
        try {
          const user = await AuthService.getUserByToken()
          if (user) {
            set({currentUser: user})
          }
        } catch (err) {
          console.error(err)
          set({authKey: undefined})
        }
      },
      saveAuth: (auth: AuthModel | undefined) => {
        if (auth) {
          set({authKey: auth.auth_key})
        }
      },
    }),
    {
      name: 'auth-store',
      enabled: !isProduction,
    },
  ),
)

// * Selectors to prevent unnecessary re-renders
export const useAuthKey = () => useAuthStore(state => state.authKey)
export const useCurrentUser = () => useAuthStore(state => state.currentUser)
export const useSetAuthKey = () => useAuthStore(state => state.setAuthKey)
export const useSetCurrentUser = () => useAuthStore(state => state.setCurrentUser)
export const useInitAuth = () => useAuthStore(state => state.init)
export const useClearTokens = () => useAuthStore(state => state.clearTokens)
export const useRequestUser = () => useAuthStore(state => state.requestUser)
export const useSaveAuth = () => useAuthStore(state => state.saveAuth)

// Logout function
export const useLogout = () => {
  const queryClient = useQueryClient()
  const resetLayoutStore = useResetStore()
  const setCurrentUser = useSetCurrentUser()
  const setAuthKey = useSetAuthKey()

  const logout = async () => {
    if (StorageService.has('auth_key')) {
      await AuthService.logoutUser()
    }
    setCurrentUser(undefined)
    setAuthKey(undefined)

    sessionStorage.removeItem('hui-util-store')
    StorageService.removeAuthTokens()
    queryClient.clear()
    resetLayoutStore()
    if (isProduction) {
      mixpanel.reset()
    }
  }
  return logout
}
